import axiosInstance from "../../axiosInstance";
import {
  createSlice,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import {
  setNewBooking,
  setBookingPagination,
  setAprroveBooking,
  setCompleteBooking,
} from "../BookingSlice";

export const bookingData = createAsyncThunk(
  "Bookings/AllBookings",
  async ({ ids, page, restraurantId, token }, { dispatch }) => {
    try {
      const url = `/booking_turbo/${restraurantId}`;
      const res = await axiosInstance.get(url, {
        params: {
          restaurantIds: ids,
          page: page,
        },
        headers: { Authorization: token },
      });

      if (res) {
        // console.log(res.data, 'CheckBookings')
        const { items, ...rest } = res?.data;
        // console.log(items, 'CheckBookings')
        dispatch(setNewBooking(items));
        dispatch(setBookingPagination(rest));
        // dispatch(setCompleteBooking(items))
      }
      return res;
    } catch (err) {
      console.log(err.message);
    }
  }
);

export const setBookings = createAsyncThunk(
  "setContent",
  async ({ id, rejectedStatus, Approved, token }, { dispatch }) => {
    try {
      const response = await axiosInstance.put(
        `/restaurant_owner/bookingsturbo_status/${id}`,
        {
          rejectedStatus: rejectedStatus,
          Approved: Approved,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (response) {
        dispatch(setAprroveBooking({ id, rejectedStatus, Approved }));
      }
      return response.data;
    } catch (err) {
      console.log(err.message);
    }
  }
);

// export const newMember = createAsyncThunk(
//   "newMember",
//   async ({ token }, { dispatch }) => {
//     console.log("Creating new member...");
//     try {
//       const response = await axiosInstance.get(`/get_restaurant_owners/3556`, {
//         headers: {
//           Authorization: token,
//         },
//       });
//       dispatch(setNewMember(response.data));
//       return response.data;
//     } catch (err) {
//       console.log(err.message);
//       throw err;
//     }
//   }
// );

// const initialState = {
//   newMember: {},
// };

// const newMemberSlice = createSlice({
//   name: "newMember",
//   initialState,
//   reducers: {
//     setNewMember(state, action) {
//       state.newMember = action.payload;
//     },
//   },
// });

// export const { setNewMember } = newMemberSlice.actions;

// const new_Member = (state) => state.newMember.newMember;

// export const selectNewMember = createDraftSafeSelector(
//   new_Member,
//   (newMember) => newMember
// );

// export default newMemberSlice.reducer;
