import { TypingIndicator, useChannelStateContext } from "stream-chat-react";
import "./CustomChannelHeader.css";

export const CustomChannelHeader = (props) => {
  const { title } = props;
  // console.log(title);

  const { channel } = useChannelStateContext();
  const { name } = channel.data || {};

  return (
    <div className="str-chat__header-livestream">
      <div className="header-title">
        <div className="header-item">
          <span className="header-pound">#</span>
          {title || name}
        </div>
        <TypingIndicator />
      </div>
    </div>
  );
};
