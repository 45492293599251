import "stream-chat-react/dist/css/v2/index.css";
import "./Chat_influencer.css";
import { CustomChannelHeader } from "./CustomChannelHeadder";
import { useDispatch, useSelector } from "react-redux";
import {
  selectEmail,
  selectId,
  selectIsLoggedIn,
  selectName,
} from "../redux/LoginSlice";

import { useEffect, useState } from "react";
import { StreamChat } from "stream-chat";
import {
  Chat,
  Channel,
  MessageInput,
  MessageList,
  Thread,
  Window,
  LoadingIndicator,
} from "stream-chat-react";
import { selectUserTurbo } from "../redux/ModalSlice";
import { newMember, selectNewMember } from "../redux/NewMemberSlice";
import { useParams } from "react-router-dom";

const apiKey = process.env.REACT_APP_STREAM_API_KEY;

const ChatInfluencer = () => {
  const [client, setClient] = useState(null);
  const [channel, setChannel] = useState(null);
  const email = useSelector(selectEmail);
  // const data = useSelector(BookingItemData);
  const id = useSelector(selectId);
  const owner_name = useSelector(selectName);
  const channelId_from_api = useSelector(selectUserTurbo);
  // console.log(channel_id.id);

  const memberArr = useSelector(selectNewMember);
  // console.log(memberData);

  const { id: selected_channel_id } = useParams();

  // Get the channel data from Redux
  // const channelData = useSelector(selectUserTurbo);

  // If channelData is an object, use it directly
  // const channel_id = channelData.id === channelId ? channelData : null;
  // console.log("every time new id?", channel_id);
  // console.log(channelId);

  const dispatch = useDispatch();

  const token = useSelector(selectIsLoggedIn);

  useEffect(() => {
    if (token) {
      dispatch(newMember({ token }));
    }
  }, [dispatch, token]);

  const user = {
    id: "owner_" + id,
    name: owner_name,
  };

  let member_id = "influencer_" + channelId_from_api.user_turbo.id.toString();
  let members = [user.id, member_id];
  console.log("members: ", members);

  useEffect(() => {
    async function init() {
      const chatClient = StreamChat.getInstance(apiKey);

      let userToken = chatClient.devToken(user.id);

      await chatClient.connectUser(user, userToken);

      // console.log("123454689", JSON.stringify(channel_id));
      const channel = chatClient.channel("messaging", selected_channel_id, {
        name: `Chat with ${channelId_from_api.user_turbo.name}`,
        // name: `Chat with ${channel_id.user_turbo.name}`,
        members: members,
      });

      try {
        await Promise.all(
          memberArr.map((memberId) => channel.addMembers(["owner_" + memberId]))
        );
      } catch (error) {
        console.error("Error adding members:", error);
      }

      await channel.watch();
      setChannel(channel);
      setClient(chatClient);
    }
    init();
  }, [selected_channel_id, channelId_from_api, memberArr]);

  if (!channel || !client) return <LoadingIndicator />;

  return (
    <Chat client={client} theme="messaging dark">
      <Channel channel={channel}>
        <Window>
          <CustomChannelHeader title={channelId_from_api.user_turbo.name} />
          {/* <CustomChannelHeader title={channel_id.user_turbo.name} /> */}
          <MessageList />
          <MessageInput />
        </Window>
        <Thread />
      </Channel>
    </Chat>
  );
};
export default ChatInfluencer;
